<template>
    <div>
      <!--TITULOS  -->
      <div class="air__utils__heading"> <h5>Master de Equipos de Laboratorio</h5> </div>
      <my-breadcrumbs :routes="routes"/>
      <!--TITULOS  -->
      <a-tabs :animated="false" type="card">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="experiment" />
            Equipos de Laboratorio
          </span>
          <auxiliarMasters auxiliar_master_type="Equipos de Laboratorio"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="schedule" />
            Checks para Equipos de Laboratorio
          </span>
          <checksEquipmentSources equipment_type="Equipos de Laboratorio"/>
        </a-tab-pane>
      </a-tabs>
    </div>
</template>
<script>
import { fractalMixin } from '@/mixins/fractalMixin'
import auxiliarMasters from '@/views/geocycle/components/auxiliarMasters'
import checksEquipmentSources from '@/views/geocycle/components/checksEquipmentSources'
export default {
  name: 'laboratoryEquipment',
  mixins: [fractalMixin],
  components: {
    auxiliarMasters,
    checksEquipmentSources,
  },
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          name: 'laboratories',
          breadcrumbName: 'Laboratorios',
        },
        {
          breadcrumbName: 'Master de Equipos de Laboratorio',
          aIcon: '',
        },
      ],
    }
  },
}
</script>
<style scoped></style>
